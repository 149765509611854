<template lang="pug">
div
</template>
<script>
export default {
    mounted () {
        console.log('logging out');
        this.$store.dispatch('logout');
        // this.$auth.logout('aad');
    }
};
</script>